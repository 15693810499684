@font-face {
  font-family: 'Roboto';
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url('./fonts/Roboto-Medium.woff2') format('woff2'),
    url('./fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:
    local('Roboto Regular'),
    local('Roboto-Regular'),
    url('./fonts/Roboto-Regular.woff2') format('woff2'),
    url('./fonts/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:
    local('Roboto SemiBold'),
    local('Roboto-SemiBold'),
    url('./fonts/Roboto-SemiBold.woff2') format('woff2'),
    url('./fonts/Roboto-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:
    local('Roboto Bold'),
    local('Roboto-Bold'),
    url('./fonts/Roboto-Bold.woff2') format('woff2'),
    url('./fonts/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
